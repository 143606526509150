import React, { useState } from "react"
import { Link } from "gatsby"
import Logo from "./logo"
import { useSiteMetadata } from "./use-site-metadata"
import styled from "styled-components"

const activeMobileLink = {
  color: "white",
  borderBottom: "solid 3px #00a5bd",
}

const MenuIcon = styled.button`
  position: fixed;
  top: 35px;
  right: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  z-index: 10;

  div {
    width: 30px;
    height: 2px;
    border-radius: 8px;
    transform-origin: 2px;
    background: white;
    position: relative;
    transition: opacity 300ms, transform 300ms;

    :first-child {
      transform: ${({ nav }) => (nav ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ nav }) => (nav ? "0" : "1")};
    }
    :nth-child(3) {
      transform: ${({ nav }) => (nav ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`

const MenuLinks = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  background: #003f77;
  position: absolute;
  top: 0;
  right: 0;
  transform: ${({ nav }) => (nav ? "translateY(0)" : "translateY(-100%)")};
  transition: transform 300ms;
  z-index: 9;
`

const MobileMenu = () => {
  const { menuLinks } = useSiteMetadata()
  const [nav, showNav] = useState(false)

  return (
    <div className="mobile-header">
      <MenuIcon
        className="mobile-menu-button"
        nav={nav}
        onClick={() => showNav(!nav)}
      >
        <div></div>
        <div></div>
        <div></div>
      </MenuIcon>
      <MenuLinks nav={nav}>
        <Logo />
        <ul className="mobile-nav">
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link
                to={link.slug}
                activeStyle={activeMobileLink}
                className="mobile-nav-link"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </MenuLinks>
    </div>
  )
}

export default MobileMenu
