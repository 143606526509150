import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { useSiteMetadata } from "./use-site-metadata"
import Logo from "./logo"
import MobileMenu from "./MobileMenu"

const activeLinkStyle = {
  color: "white",
  borderBottom: "solid 3px #00a5bd",
}

const Header = ({ siteTitle }) => {
  const { menuLinks } = useSiteMetadata()

  return (
    <header>
      <nav className="shadow">
        <Logo />
        <MobileMenu />
        <ul className="desktop-nav">
          {menuLinks.map(link => (
            <li key={link.name}>
              <Link
                to={link.slug}
                activeStyle={activeLinkStyle}
                className="nav-link"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
