import { useStaticQuery, graphql } from 'gatsby'

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query siteMetaData {
        site {
          siteMetadata {
            menuLinks {
              name
              slug
            }
            footerLinks {
                name
                slug
            }
            logo
            address
            phone
          }
        }
      }
    `
  )
  return site.siteMetadata
}
