import * as React from "react"
import { Link } from "gatsby"
import LogoImg from "../../static/SammonsIndustrial_vert-white.png"

const Logo = () => {
  return (
    <Link to="/">
      <img
        src={LogoImg}
        layout="fixed"
        height={100}
        display="inline-block"
        alt="logo"
      />
    </Link>
  )
}

export default Logo
