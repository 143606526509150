import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "./logo"
import { useSiteMetadata } from "./use-site-metadata"

const activeLinkStyle = {
  color: "white",
}

const Footer = ({ siteTitle }) => {
  const siteMetadata = useSiteMetadata()
  return (
    <footer>
      <div className="footer-left">
        <Logo />
        <div className="footer-contact-info">
          <div>14241 Dallas Parkway, Suite 880, Dallas, TX 75254</div>
          <div>{siteMetadata.email}</div>
          <div>{siteMetadata.phone}</div>
        </div>
      </div>
      <div className="footer-right">
        <ul>
          {siteMetadata.footerLinks.map(link => (
            <li key={link.name}>
              <Link
                to={link.slug}
                activeStyle={activeLinkStyle}
                className="footer-link"
              >
                {link.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </footer>
  )
}
Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
